<template>
  <div class="app-container h">
    <department-select v-model="query.deptId" @change="toQuery" style="width: 320px; margin-right: 20px;" />

    <div class="flex v">
      <div class="head-container">
        <el-input v-model="query.keyword" clearable placeholder="输入岗位名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-select v-model="query.enabled" filterable clearable placeholder="状态" class="filter-item" style="width: 90px" @change="toQuery">
          <el-option v-for="item in dicts" :key="item.id" :label="item.label" :value="item.value" />
        </el-select>
        <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
        <div style="display: inline-block;margin: 0px 2px;">
          <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>

      <el-table v-loading="loading" row-key="id" :data="data" size="small" height="200px">
        <el-table-column prop="name" label="名称" />
        <el-table-column label="所属部门">
          <template slot-scope="scope">
            <div>{{ scope.row.deptSuperiorName ? scope.row.deptSuperiorName + ' / ' : '' }}{{ scope.row.dept.name }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序">
          <template slot-scope="scope">{{ scope.row.sort }}</template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-for="item in dicts" :key="item.id">
              <el-tag v-if="scope.row.enabled.toString() === item.value" :type="scope.row.enabled ? '' : 'info'">{{ item.label }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建日期">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.createTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100px" fixed="right">
          <div class="row-commands" slot-scope="scope">
            <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
            <el-popover :ref="scope.row.id" placement="top" width="180">
              <p>确定删除本条数据吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    </div>

    <eForm ref="form" :is-add="isAdd" :dicts="dicts" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import initDict from "@/mixins/initDict";
import { del } from "@/api/job";
import { parseTime } from "@/utils/index";
import eForm from "./form";
import DepartmentSelect from "../../assembly/departmentSelect";

export default {
  name: "Job",
  components: { eForm, DepartmentSelect },
  mixins: [initData, initDict],
  data() {
    return {
      delLoading: false,
      query: {
        keyword: null,
        enabled: null,
      },
    };
  },
  created() {
    this.getDict("job_status").then((_) => {
      this.init();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "api/job";
      const sort = "sort,asc";
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
        deptId: this.query.deptId,
        enabled: this.query.enabled,
        name: this.query.keyword,
      };
      return true;
    },
    getDeptDatas() {
      const sort = "id,desc";
      const params = { sort: sort };
      if (this.deptName) {
        params["name"] = this.deptName;
      }
      getDepts(params).then((res) => {
        this.depts = res.content;
      });
    },
    handleNodeClick(data) {
      this.deptId = data.id;
      this.init();
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    add() {
      this.isAdd = true;
      this.$refs.form.getMaxSortNo();
      this.$refs.form.dialog = true;
    },
    edit(data) {
      this.isAdd = false;
      const _this = this.$refs.form;
      _this.form = {
        id: data.id,
        name: data.name,
        sort: data.sort,
        enabled: data.enabled.toString(),
        createTime: data.createTime,
        dept: { id: data.dept.id },
      };
      _this.deptId = data.dept.id;
      _this.dialog = true;
    },
  },
};
</script>

<style scoped>
</style>
