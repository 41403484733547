<template>
  <div class="v bc-l">
    <el-input v-model="query.keyword" clearable placeholder="输入部门名称搜索" class="filter-item" @keypress.enter.native="toQuery" @clear="toQuery">
      <el-button icon="el-icon-search" slot="append" @click="toQuery" />
    </el-input>

    <div class="flex scroll-able">
      <el-tree :data="data" :props="{label: 'name'}" highlight-current :expand-on-click-node="false" default-expand-all @node-click="handleNodeClick" />
    </div>
  </div>
</template>

<script>
import initData from "@/mixins/initData";

export default {
  props: {
    value: Number
  },
  mixins: [initData],
  mounted() {
    this.init();
  },
  methods: {
    beforeInit() {
      this.url = "api/dept";
      this.params = { sort: "id,desc", name: this.query.keyword };
      return true;
    },
    handleNodeClick(data) {
      if (data.id !== this.value) {
        this.$emit("input", data.id);
        this.$emit("change", data);
      }
    }
  }
};
</script>